import { GoogleLogin } from "react-google-login";
import { connect } from 'react-redux';
import { login,setLoggedIn } from '../actions/login/authssoActions';
import googleImage from './g.jpg';
// const clientId="818058364680-0h15mo0i9aprr7g47hriu8puib7eb0aa.apps.googleusercontent.com";
const clientId="818058364680-6d3u6vo52bkqb50r6bg6ph31tis44ibg.apps.googleusercontent.com";

const Login = ({ login,onClosePopup,isLoggedIn }) => {
  console.log(googleImage)
    
    const handleLoginWithGoogle = () => {
        const currentUrl = window.location.href;
        const updatedUrl = `${currentUrl}${currentUrl.includes('?') ? '&' : '?'}google_login=1`;
        window.location.href = `http://sso.fusionepos.co.uk/google_redirect.php?page=${encodeURIComponent(updatedUrl)}`;
      };
      

    const onSuccess= (res) => {
        onClosePopup();
        const postData = {
                verfication_code: res.googleId,
                email: res.profileObj.email,
                login_type: 1,
                first_name:res.profileObj.givenName,
                last_name:res.profileObj.familyName
            };  
        // login(JSON.stringify(postData));
    }

    const onFailure= (res) => {
        console.log("Login Failed! res:",res);
    }

    return (
        // <div id="signInButton">
        <div style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            color: 'rgba(0, 0, 0, 0.54) !important',
            boxShadow: 'rgba(0, 0, 0, 0.24) 0px 2px 2px 0px, rgba(0, 0, 0, 0.24) 0px 0px 1px 0px',
            padding: '0px',
            borderRadius: '23px',
            border: '1px solid transparent',
            fontSize: '14px',
            fontWeight: '634',
            fontFamily: 'Roboto, sans-serif',
            height: '40px',
            width: '80%',  
            textAlign: 'center',
            gap: '10px',
            margin: '0 auto', 
            cursor:'pointer',
        }}
        onClick={handleLoginWithGoogle}
        className="google_btn"
        data-Fus="fus-test-Googleloginbtn"
        >
            
            <img src={googleImage} style={{ width: '20px', height: '20px' }} /> Continue With Google
           
        </div>
             
    )
}

const mapStateToProps = (state) => {
    return {
      isLoggedIn: state.userdata.isLoggedIn,
      userData: state.userdata.userData,
    };
  };
  
  const mapDispatchToProps = {
    login,
    setLoggedIn,
  };
  
  export default connect(mapStateToProps, mapDispatchToProps)(Login);